var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ExternalService } from './src/external.service';
import { ResourceService } from './src/resource.service';
import 'rxjs';
import { AuthInterceptor } from "./src/interceptor/AuthInterceptor";
import { TokenConfigService } from "./src/interceptor/TokenConfigService";
export { ExternalService } from './src/external.service';
export { RestService } from './src/rest.service';
export { Resource } from './src/resource';
export { ResourceArray } from './src/resource-array';
export { ResourceHelper } from './src/resource-helper';
export { CacheHelper } from './src/cache/cache.helper';
export { EvictStrategy } from './src/cache/cache.helper';
var AngularHalModule = /** @class */ (function () {
    function AngularHalModule() {
    }
    AngularHalModule_1 = AngularHalModule;
    AngularHalModule.forRoot = function (tokenConfig) {
        return {
            ngModule: AngularHalModule_1,
            providers: [
                ExternalService,
                HttpClient,
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: AuthInterceptor,
                    multi: true,
                    deps: [TokenConfigService]
                },
                {
                    provide: TokenConfigService,
                    useValue: tokenConfig == null ? '' : tokenConfig
                },
                {
                    provide: ResourceService,
                    useClass: ResourceService,
                    deps: [ExternalService]
                }
            ]
        };
    };
    var AngularHalModule_1;
    AngularHalModule = AngularHalModule_1 = __decorate([
        NgModule({
            imports: [HttpClientModule],
            declarations: [],
            exports: [HttpClientModule],
            providers: [
                ExternalService,
                HttpClient,
                {
                    provide: ResourceService,
                    useClass: ResourceService,
                    deps: [ExternalService]
                }
            ]
        })
    ], AngularHalModule);
    return AngularHalModule;
}());
export { AngularHalModule };
