var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { ResourceHelper } from './resource-helper';
import { Injectable } from '@angular/core';
import { CustomEncoder } from './CustomEncoder';
import { Utils } from './Utils';
import { CacheHelper } from './cache/cache.helper';
var Resource = /** @class */ (function () {
    function Resource() {
    }
    Object.defineProperty(Resource.prototype, "subtypes", {
        get: function () {
            return this._subtypes;
        },
        set: function (_subtypes) {
            this._subtypes = _subtypes;
        },
        enumerable: true,
        configurable: true
    });
    // Get related resource
    Resource.prototype.getRelation = function (type, relation, builder, expireMs, isCacheActive) {
        var _this = this;
        if (expireMs === void 0) { expireMs = CacheHelper.defaultExpire; }
        if (isCacheActive === void 0) { isCacheActive = true; }
        var result = new type();
        if (this.existRelationLink(relation)) {
            if (CacheHelper.ifPresent(this.getRelationLinkHref(relation), null, null, isCacheActive))
                return observableOf(CacheHelper.get(this.getRelationLinkHref(relation)));
            var observable = ResourceHelper.getHttp().get(ResourceHelper.getProxy(this.getRelationLinkHref(relation)), { headers: ResourceHelper.headers, observe: 'response' });
            return observable.pipe(map(function (response) {
                if (builder) {
                    var data = response.body;
                    for (var _i = 0, _a = Object.keys(data['_links']); _i < _a.length; _i++) {
                        var embeddedClassName = _a[_i];
                        if (embeddedClassName == 'self') {
                            var href = data._links[embeddedClassName].href;
                            var idx = href.lastIndexOf('/');
                            var realClassName = href.replace(ResourceHelper.getRootUri(), '').substring(0, idx);
                            result = ResourceHelper.searchSubtypes(builder, realClassName, result);
                            break;
                        }
                    }
                }
                var resource = ResourceHelper.instantiateResourceFromResponse(result, response);
                CacheHelper.put(_this.getRelationLinkHref(relation), resource, expireMs);
                return resource;
            }));
        }
        else {
            return observableOf(null);
        }
    };
    // Get collection of related resources
    Resource.prototype.getRelationArray = function (type, relation, _embedded, options, builder, expireMs, isCacheActive) {
        var _this = this;
        if (expireMs === void 0) { expireMs = CacheHelper.defaultExpire; }
        if (isCacheActive === void 0) { isCacheActive = true; }
        var params = ResourceHelper.optionParams(new HttpParams({ encoder: new CustomEncoder() }), options);
        var result = ResourceHelper.createEmptyResult(Utils.isNullOrUndefined(_embedded) ? '_embedded' : _embedded);
        if (this.existRelationLink(relation)) {
            if (CacheHelper.ifPresent(this.getRelationLinkHref(relation), null, options, isCacheActive))
                return observableOf(CacheHelper.getArray(this.getRelationLinkHref(relation)));
            var observable = ResourceHelper.getHttp().get(ResourceHelper.getProxy(this.getRelationLinkHref(relation)), {
                headers: ResourceHelper.headers,
                observe: 'response',
                params: params
            });
            return observable
                .pipe(map(function (response) { return ResourceHelper.instantiateResourceCollection(type, response, result, builder); }), catchError(function (error) { return observableThrowError(error); })).pipe(map(function (array) {
                CacheHelper.putArray(_this.getRelationLinkHref(relation), array.result, expireMs);
                return array.result;
            }));
        }
        else {
            return observableOf([]);
        }
    };
    Resource.prototype.getProjection = function (type, resource, id, projectionName, expireMs, isCacheActive) {
        if (expireMs === void 0) { expireMs = CacheHelper.defaultExpire; }
        if (isCacheActive === void 0) { isCacheActive = true; }
        var uri = this.getResourceUrl(resource).concat('/', id).concat('?projection=' + projectionName);
        var result = new type();
        if (CacheHelper.ifPresent(uri, null, null, isCacheActive))
            return observableOf(CacheHelper.get(uri));
        var observable = ResourceHelper.getHttp().get(uri, { headers: ResourceHelper.headers, observe: 'response' });
        return observable.pipe(map(function (response) {
            var resource = ResourceHelper.instantiateResourceFromResponse(result, response);
            CacheHelper.put(uri, resource, expireMs);
            return resource;
        }), catchError(function (error) { return observableThrowError(error); }));
    };
    Resource.prototype.getProjectionArray = function (type, resource, projectionName, expireMs, isCacheActive) {
        if (expireMs === void 0) { expireMs = CacheHelper.defaultExpire; }
        if (isCacheActive === void 0) { isCacheActive = true; }
        var uri = this.getResourceUrl(resource).concat('?projection=' + projectionName);
        var result = ResourceHelper.createEmptyResult('_embedded');
        if (CacheHelper.ifPresent(uri, null, null, isCacheActive))
            return observableOf(CacheHelper.getArray(uri));
        var observable = ResourceHelper.getHttp().get(uri, { headers: ResourceHelper.headers, observe: 'response' });
        return observable.pipe(map(function (response) { return ResourceHelper.instantiateResourceCollection(type, response, result); }), map(function (array) {
            CacheHelper.putArray(uri, array.result, expireMs);
            return array.result;
        }));
    };
    Resource.prototype.getResourceUrl = function (resource) {
        var url = ResourceHelper.getURL();
        if (!url.endsWith('/')) {
            url = url.concat('/');
        }
        if (resource) {
            return url.concat(resource);
        }
        url = url.replace('{?projection}', '');
        return url;
    };
    Resource.prototype.getRelationLinkHref = function (relation) {
        if (this._links[relation].templated)
            return this._links[relation].href.replace('{?projection}', '');
        return this._links[relation].href;
    };
    Resource.prototype.existRelationLink = function (relation) {
        return !Utils.isNullOrUndefined(this._links) && !Utils.isNullOrUndefined(this._links[relation]);
    };
    // Adds the given resource to the bound collection by the relation
    Resource.prototype.addRelation = function (relation, resource) {
        if (this.existRelationLink(relation)) {
            var header = ResourceHelper.headers.append('Content-Type', 'text/uri-list');
            return ResourceHelper.getHttp()
                .put(ResourceHelper.getProxy(this.getRelationLinkHref(relation)), resource._links.self.href, { headers: header });
        }
        else {
            return observableThrowError('no relation found');
        }
    };
    // Bind the given resource to this resource by the given relation
    Resource.prototype.updateRelation = function (relation, resource) {
        if (this.existRelationLink(relation)) {
            var header = ResourceHelper.headers.append('Content-Type', 'text/uri-list');
            return ResourceHelper.getHttp().patch(ResourceHelper.getProxy(this.getRelationLinkHref(relation)), resource._links.self.href, { headers: header });
        }
        else {
            return observableThrowError('no relation found');
        }
    };
    // Bind the given resource to this resource by the given relation
    Resource.prototype.substituteRelation = function (relation, resource) {
        if (this.existRelationLink(relation)) {
            var header = ResourceHelper.headers.append('Content-Type', 'text/uri-list');
            return ResourceHelper.getHttp().put(ResourceHelper.getProxy(this.getRelationLinkHref(relation)), resource._links.self.href, { headers: header });
        }
        else {
            return observableThrowError('no relation found');
        }
    };
    // Unbind the resource with the given relation from this resource
    Resource.prototype.deleteRelation = function (relation, resource) {
        if (this.existRelationLink(relation)) {
            var link = resource._links['self'].href;
            var idx = link.lastIndexOf('/') + 1;
            if (idx == -1)
                return observableThrowError('no relation found');
            var relationId = link.substring(idx);
            return ResourceHelper.getHttp().delete(ResourceHelper.getProxy(this.getRelationLinkHref(relation) + '/' + relationId), { headers: ResourceHelper.headers });
        }
        else {
            return observableThrowError('no relation found');
        }
    };
    Resource = __decorate([
        Injectable(),
        __metadata("design:paramtypes", [])
    ], Resource);
    return Resource;
}());
export { Resource };
