var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ResourceHelper } from './resource-helper';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ExternalService } from './external.service';
import { CustomEncoder } from './CustomEncoder';
import * as url from 'url';
var ResourceService = /** @class */ (function () {
    function ResourceService(externalService) {
        this.externalService = externalService;
    }
    ResourceService_1 = ResourceService;
    ResourceService.getURL = function () {
        return ResourceHelper.getURL();
    };
    ResourceService.prototype.handleError = function (error) {
        return observableThrowError(error);
    };
    ResourceService.prototype.getAll = function (type, resource, _embedded, options, subType) {
        var _this = this;
        var uri = this.getResourceUrl(resource);
        var params = ResourceHelper.optionParams(new HttpParams({ encoder: new CustomEncoder() }), options);
        var result = ResourceHelper.createEmptyResult(_embedded);
        this.setUrls(result);
        result.sortInfo = options ? options.sort : undefined;
        var observable = ResourceHelper.getHttp().get(uri, { headers: ResourceHelper.headers, observe: 'response', params: params });
        return observable.pipe(map(function (response) { return ResourceHelper.instantiateResourceCollection(type, response, result, subType); }), catchError(function (error) { return _this.handleError(error); }));
    };
    ResourceService.prototype.get = function (type, resource, id, params, builder) {
        var _this = this;
        var self = this;
        var uri = this.getResourceUrl(resource).concat('/', id);
        var result = new type();
        var httpParams = ResourceHelper.params(new HttpParams(), params);
        this.setUrlsResource(result);
        var observable = ResourceHelper.getHttp().get(uri, { headers: ResourceHelper.headers, observe: 'response', params: httpParams });
        return observable.pipe(map(function (response) {
            if (builder) {
                var linkHref = url.parse(response.body._links.self.href).pathname;
                var regex = /([A-Za-z0-9]+)\/([A-Za-z0-9]+)\/([A-Za-z0-9]+)/g;
                var match = regex.exec(linkHref);
                if (match != null) {
                    var embeddedClassName = match[2];
                    result = ResourceHelper.searchSubtypes(builder, embeddedClassName, result);
                }
            }
            return ResourceHelper.instantiateResourceFromResponse(result, response);
        }), catchError(function (error) { return _this.handleError(error); }));
    };
    ResourceService.prototype.getBySelfLink = function (type, resourceLink) {
        var _this = this;
        var result = new type();
        this.setUrlsResource(result);
        var observable = ResourceHelper.getHttp().get(ResourceHelper.getProxy(resourceLink), { headers: ResourceHelper.headers, observe: 'response' });
        return observable.pipe(map(function (response) { return ResourceHelper.instantiateResourceFromResponse(result, response); }), catchError(function (error) { return _this.handleError(error); }));
    };
    ResourceService.prototype.search = function (type, query, resource, _embedded, options, subType) {
        var _this = this;
        var uri = this.getResourceUrl(resource).concat('/search/', query);
        var params = ResourceHelper.optionParams(new HttpParams({ encoder: new CustomEncoder() }), options);
        var result = ResourceHelper.createEmptyResult(_embedded);
        this.setUrls(result);
        var observable = ResourceHelper.getHttp().get(uri, { headers: ResourceHelper.headers, observe: 'response', params: params });
        return observable.pipe(map(function (response) { return ResourceHelper.instantiateResourceCollection(type, response, result, subType); }), catchError(function (error) { return _this.handleError(error); }));
    };
    ResourceService.prototype.searchSingle = function (type, query, resource, options) {
        var _this = this;
        var uri = this.getResourceUrl(resource).concat('/search/', query);
        var params = ResourceHelper.optionParams(new HttpParams({ encoder: new CustomEncoder() }), options);
        var result = new type();
        this.setUrlsResource(result);
        var observable = ResourceHelper.getHttp().get(uri, { headers: ResourceHelper.headers, observe: 'response', params: params });
        return observable.pipe(map(function (response) { return ResourceHelper.instantiateResourceFromResponse(result, response); }), catchError(function (error) { return _this.handleError(error); }));
    };
    ResourceService.prototype.customQuery = function (type, query, resource, _embedded, options, subType) {
        var _this = this;
        var uri = this.getResourceUrl(resource + query);
        var params = ResourceHelper.optionParams(new HttpParams({ encoder: new CustomEncoder() }), options);
        var result = ResourceHelper.createEmptyResult(_embedded);
        this.setUrls(result);
        var observable = ResourceHelper.getHttp().get(uri, { headers: ResourceHelper.headers, observe: 'response', params: params });
        return observable.pipe(map(function (response) { return ResourceHelper.instantiateResourceCollection(type, response, result, subType); }), catchError(function (error) { return _this.handleError(error); }));
    };
    ResourceService.prototype.customQueryPost = function (type, query, resource, _embedded, options, body, subType) {
        var _this = this;
        var uri = this.getResourceUrl(resource + query);
        var params = ResourceHelper.optionParams(new HttpParams(), options);
        var result = ResourceHelper.createEmptyResult(_embedded);
        this.setUrls(result);
        var observable = ResourceHelper.getHttp().post(uri, body, { headers: ResourceHelper.headers, observe: 'response', params: params });
        return observable.pipe(map(function (response) { return ResourceHelper.instantiateResourceCollection(type, response, result, subType); }), catchError(function (error) { return _this.handleError(error); }));
    };
    ResourceService.prototype.getByRelation = function (type, resourceLink) {
        var _this = this;
        var result = new type();
        this.setUrlsResource(result);
        var observable = ResourceHelper.getHttp().get(resourceLink, { headers: ResourceHelper.headers, observe: 'response' });
        return observable.pipe(map(function (response) { return ResourceHelper.instantiateResourceFromResponse(result, response); }), catchError(function (error) { return _this.handleError(error); }));
    };
    ResourceService.prototype.getByRelationArray = function (type, resourceLink, _embedded, builder) {
        var _this = this;
        var result = ResourceHelper.createEmptyResult(_embedded);
        this.setUrls(result);
        var observable = ResourceHelper.getHttp().get(resourceLink, { headers: ResourceHelper.headers, observe: 'response' });
        return observable.pipe(map(function (response) { return ResourceHelper.instantiateResourceCollection(type, response, result, builder); }), catchError(function (error) { return _this.handleError(error); }));
    };
    ResourceService.prototype.getProjection = function (type, resource, id, projectionName) {
        var _this = this;
        var uri = this.getResourceUrl(resource).concat('/', id).concat('?projection=' + projectionName);
        var result = new type();
        var observable = ResourceHelper.getHttp().get(uri, { headers: ResourceHelper.headers, observe: 'response' });
        return observable.pipe(map(function (response) { return ResourceHelper.instantiateResourceFromResponse(result, response); }), catchError(function (error) { return _this.handleError(error); }));
    };
    ResourceService.prototype.getProjectionArray = function (type, resource, projectionName) {
        var _this = this;
        var uri = this.getResourceUrl(resource).concat('?projection=' + projectionName);
        var result = ResourceHelper.createEmptyResult('_embedded');
        var observable = ResourceHelper.getHttp().get(uri, { headers: ResourceHelper.headers, observe: 'response' });
        return observable
            .pipe(map(function (response) { return ResourceHelper.instantiateResourceCollection(type, response, result); }), catchError(function (error) { return _this.handleError(error); })).pipe(map(function (resourceArray) {
            return resourceArray.result;
        }));
    };
    ResourceService.prototype.count = function (resource, query, options) {
        var _this = this;
        var uri = this.getResourceUrl(resource).concat('/search/' + (query === undefined ? 'countAll' : query));
        var params = ResourceHelper.optionParams(new HttpParams(), options);
        return ResourceHelper.getHttp().get(uri, { headers: ResourceHelper.headers, observe: 'response', params: params }).pipe(map(function (response) { return Number(response.body); }), catchError(function (error) { return _this.handleError(error); }));
    };
    ResourceService.prototype.create = function (selfResource, entity) {
        var _this = this;
        var uri = ResourceHelper.getURL() + selfResource;
        var payload = ResourceHelper.resolveRelations(entity);
        this.setUrlsResource(entity);
        var observable = ResourceHelper.getHttp().post(uri, payload, { headers: ResourceHelper.headers, observe: 'response' });
        return observable.pipe(map(function (response) {
            if (response.status >= 200 && response.status <= 207)
                return ResourceHelper.instantiateResourceFromResponse(entity, response);
            else if (response.status == 500) {
                var body = response.body;
                return _this.handleError(body.error);
            }
        }), catchError(function (error) { return _this.handleError(error); }));
    };
    ResourceService.prototype.update = function (entity) {
        var _this = this;
        var uri = ResourceHelper.getProxy(entity._links.self.href);
        var payload = ResourceHelper.resolveRelations(entity);
        this.setUrlsResource(entity);
        var observable = ResourceHelper.getHttp().put(uri, payload, { headers: ResourceHelper.headers, observe: 'response' });
        return observable.pipe(map(function (response) {
            if (response.status >= 200 && response.status <= 207)
                return ResourceHelper.instantiateResourceFromResponse(entity, response);
            else if (response.status == 500) {
                var body = response.body;
                return _this.handleError(body.error);
            }
        }), catchError(function (error) { return _this.handleError(error); }));
    };
    ResourceService.prototype.patch = function (entity) {
        var _this = this;
        var uri = ResourceHelper.getProxy(entity._links.self.href);
        var payload = ResourceHelper.resolveRelations(entity);
        this.setUrlsResource(entity);
        var observable = ResourceHelper.getHttp().patch(uri, payload, { headers: ResourceHelper.headers, observe: 'response' });
        return observable.pipe(map(function (response) {
            if (response.status >= 200 && response.status <= 207)
                return ResourceHelper.instantiateResourceFromResponse(entity, response);
            else if (response.status == 500) {
                var body = response.body;
                return _this.handleError(body.error);
            }
        }), catchError(function (error) { return _this.handleError(error); }));
    };
    ResourceService.prototype.delete = function (entity) {
        var _this = this;
        var uri = ResourceHelper.getProxy(entity._links.self.href);
        return ResourceHelper.getHttp().delete(uri, { headers: ResourceHelper.headers })
            .pipe(catchError(function (error) { return _this.handleError(error); }));
    };
    ResourceService.prototype.hasNext = function (resourceArray) {
        return resourceArray.next_uri != undefined;
    };
    ResourceService.prototype.hasPrev = function (resourceArray) {
        return resourceArray.prev_uri != undefined;
    };
    ResourceService.prototype.hasFirst = function (resourceArray) {
        return resourceArray.first_uri != undefined;
    };
    ResourceService.prototype.hasLast = function (resourceArray) {
        return resourceArray.last_uri != undefined;
    };
    ResourceService.prototype.next = function (resourceArray, type) {
        return resourceArray.next(type);
    };
    ResourceService.prototype.prev = function (resourceArray, type) {
        return resourceArray.prev(type);
    };
    ResourceService.prototype.first = function (resourceArray, type) {
        return resourceArray.first(type);
    };
    ResourceService.prototype.last = function (resourceArray, type) {
        return resourceArray.last(type);
    };
    ResourceService.prototype.page = function (resourceArray, type, id) {
        return resourceArray.page(type, id);
    };
    ResourceService.prototype.sortElements = function (resourceArray, type) {
        var sort = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            sort[_i - 2] = arguments[_i];
        }
        return resourceArray.sortElements.apply(resourceArray, [type].concat(sort));
    };
    ResourceService.prototype.size = function (resourceArray, type, size) {
        return resourceArray.size(type, size);
    };
    ResourceService.prototype.getResourceUrl = function (resource) {
        var url = ResourceService_1.getURL();
        if (!url.endsWith('/')) {
            url = url.concat('/');
        }
        if (resource) {
            return url.concat(resource);
        }
        url = url.replace('{?projection}', '');
        return url;
    };
    ResourceService.prototype.setUrls = function (result) {
        result.proxyUrl = this.externalService.getProxyUri();
        result.rootUrl = this.externalService.getRootUri();
    };
    ResourceService.prototype.setUrlsResource = function (result) {
        result.proxyUrl = this.externalService.getProxyUri();
        result.rootUrl = this.externalService.getRootUri();
    };
    var ResourceService_1;
    ResourceService = ResourceService_1 = __decorate([
        Injectable(),
        __metadata("design:paramtypes", [ExternalService])
    ], ResourceService);
    return ResourceService;
}());
export { ResourceService };
