var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { Inject, Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { TokenConfigService } from "./TokenConfigService";
import { Auth } from "./Auth";
var AuthInterceptor = /** @class */ (function () {
    function AuthInterceptor(config) {
        this.config = config;
    }
    AuthInterceptor.prototype.intercept = function (request, next) {
        // add authorization header with jwt token if available
        if (this.config) {
            var token = Object.assign(Auth, JSON.parse(sessionStorage.getItem(this.config.token)));
            if (request.url.indexOf(this.config.excludeAuthPath) != -1)
                return next.handle(request);
            if (token && request.url.indexOf('files/upload') != -1) {
                var headers = new HttpHeaders({
                    'Authorization': 'Bearer ' + token.access_token
                });
                request = request.clone({ headers: headers });
            }
            else if (token && (!request.url.endsWith('oauth/token') || request.url.indexOf('recuperaPassword') == -1)) {
                var ct = request.headers.has('Content-Type') ? request.headers.get('Content-Type') : 'application/json';
                var headers = new HttpHeaders({
                    'Authorization': 'Bearer ' + token.access_token,
                    'Content-Type': ct
                });
                request = request.clone({ headers: headers });
            }
        }
        return next.handle(request);
    };
    AuthInterceptor = __decorate([
        Injectable(),
        __param(0, Inject(TokenConfigService)),
        __metadata("design:paramtypes", [Object])
    ], AuthInterceptor);
    return AuthInterceptor;
}());
export { AuthInterceptor };
