var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { Inject, Injectable } from '@angular/core';
import { ResourceHelper } from './resource-helper';
import { CacheHelper } from './cache/cache.helper';
var ExternalService = /** @class */ (function () {
    function ExternalService(externalConfigurationService) {
        this.externalConfigurationService = externalConfigurationService;
        ResourceHelper.setProxyUri(externalConfigurationService.getProxyUri());
        ResourceHelper.setRootUri(externalConfigurationService.getRootUri());
        ResourceHelper.setHttp(externalConfigurationService.getHttp());
        CacheHelper.initClearCacheProcess();
    }
    ExternalService.prototype.updateExternalConfigurationHandlerInterface = function (externalConfigurationService) {
        this.externalConfigurationService = externalConfigurationService;
        ResourceHelper.setProxyUri(externalConfigurationService.getProxyUri());
        ResourceHelper.setRootUri(externalConfigurationService.getRootUri());
        ResourceHelper.setHttp(externalConfigurationService.getHttp());
    };
    ExternalService.prototype.getExternalConfiguration = function () {
        return this.externalConfigurationService.getExternalConfiguration();
    };
    ExternalService.prototype.getProxyUri = function () {
        return this.externalConfigurationService.getProxyUri();
    };
    ExternalService.prototype.getRootUri = function () {
        return this.externalConfigurationService.getRootUri();
    };
    ExternalService.prototype.getURL = function () {
        return ResourceHelper.getURL();
    };
    ExternalService.prototype.getHttp = function () {
        return ResourceHelper.getHttp();
    };
    ExternalService = __decorate([
        Injectable(),
        __param(0, Inject('ExternalConfigurationService')),
        __metadata("design:paramtypes", [Object])
    ], ExternalService);
    return ExternalService;
}());
export { ExternalService };
